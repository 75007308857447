/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './AltYMMEContentBlock.module.scss';
import cx from 'classnames';
import { useRouter } from 'next/router';
import logger from '@/utils/logger';
import { YMMEModal } from '../YMMEModal';
import { useContext, useEffect, useState } from 'react';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useLabels } from '@/hooks/useLabels';
import { SnackBarContext } from '@/components/SnackBarContext';
import { usePreferredVehicle } from '../../../header/hooks/usePreferredVehicle';
import { trackShopWithoutVehicle } from '@/utils/analytics/track/trackShopWithoutVehicle';
import { useDeleteDefaultVehicleMutation } from '../../api/deleteDefaultVehicle';
import { setInteractionLocation } from '../../utils/ymmeLocationHelper';
import type { InteractionLocation } from '@/types/analytics';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCategoryByModel } from '@/features/category/api/getCategoryModels';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import { Skeleton, View } from '@az/starc-ui';
import { VehicleSelectedYMME } from './VehicleSelectedYMME';
import { NonVehicleYMME } from './NonVehicleYMME';
import ContentContainer from '@/components/AZCustomComponent/ContentContainer';
import { useProductList } from '@/features/shelf/context/useProductList';

type Props = {
  pageType?: string;
  onUpdateVehicleSuccess?: (() => void) | undefined;
  nonShopPage?: boolean;
  removeMargins?: boolean;
};

const labelMap = {
  lblAddVehicle: 'button_YMME_Body_AddVehicle',
  lblAddMakeModel: 'add_make_model',
  yourLabel: 'label_MyAccountMyProfile_landing_RewardYour',
  hasBeenMovedLabel: 'label_YMME_Body_HasBeenMovedTo',
  lblMakeModel: 'label_MakeModel',
  lblAddYear: 'label_Add_Year',
};

export default function AltYMMEContentBlock({
  pageType,
  onUpdateVehicleSuccess,
  nonShopPage = false,
  removeMargins = false,
}: Props) {
  const router = useRouter();
  const [showYmme, setShowYmme] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { data: shelfData } = useProductList();
  const productFinderData = shelfData?.productFinderData;
  const productFilterData = shelfData?.productFilterData;
  const productRefinementIsAfterYMME = productFinderData?.isAfterYmme || !!productFilterData;

  const isPartsRoute =
    router.asPath.startsWith('/parts') || router.asPath.startsWith('/refacciones');
  const isCategoryPage = isPartsRoute && (router.query.seoUrlPath?.length || 0) >= 2;
  const [isLoading, setIsLoading] = useState(isCategoryPage);

  const preferredVehicle = usePreferredVehicle();
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();
  const isBrowsePageType = router.route === '/[...seoUrlPath]';

  const { data: categoryModelData } = useCategoryByModel({
    enabled: isCategoryPage,
    seoUrl: router.asPath,
  });

  const labels = useLabels(labelMap);

  const [selectedMethod, setSelectedMethod] = useState<string>(labels.lblMakeModel);

  const locale = useLocale();
  const isMX = locale === countryCodes.mx;
  const isBR = locale === countryCodes.ptBr;

  const handleOpenYmme = (method: string) => {
    setSelectedMethod(method);
    setShowYmme(true);
    setInteractionLocation(pageType as InteractionLocation);
  };

  const closeYmme = () => {
    setShowYmme(false);
  };

  const { mutateAsync: removeDefaultVehicle } = useDeleteDefaultVehicleMutation();
  const { showSnackBar } = useContext(SnackBarContext);

  const removeVehicle = () => {
    try {
      void removeDefaultVehicle();
    } catch (err) {
      logger.error({
        message: 'Error when removing default vehicle',
        meta: {
          error: err,
        },
      });
    } finally {
      onUpdateVehicleSuccess?.();
    }
  };

  const handleShopWithoutVehicle = () => {
    setInteractionLocation(pageType as InteractionLocation);
    removeVehicle();
    const snackMessage = preferredVehicle?.vehicleName
      ? `${labels.yourLabel} ${preferredVehicle.vehicleName} ${labels.hasBeenMovedLabel}`
      : '';
    showSnackBar(snackMessage);
    trackShopWithoutVehicle();
  };

  const vehicleAddLabel = isMobile ? labels.lblAddVehicle : labels.lblAddMakeModel;

  useEffect(() => {
    if (categoryModelData) {
      setIsLoading(false);
    }
  }, [categoryModelData]);

  if (isLoading) {
    return (
      <View className={styles.removeHorizontalPadding} height={isMobile ? '100%' : '115px'}>
        <Skeleton height="100%" width="100%" />
      </View>
    );
  }

  return (
    <ContentContainer withMargin={!removeMargins && !productRefinementIsAfterYMME}>
      <div
        className={cx(styles.container, {
          [styles.nonShopContainer]: nonShopPage,
          [styles.pdp]: pageType === 'ProductDetail',
          [styles.nonUSContainer]: isMX || isBR,
          [styles.hasVehicleContainer]: preferredVehicle,
        })}
        data-testid="ymme-top-container"
      >
        {!!preferredVehicle ? (
          <VehicleSelectedYMME
            preferredVehicle={preferredVehicle}
            setShowYmme={setShowYmme}
            handleShopWithoutVehicle={handleShopWithoutVehicle}
          />
        ) : (
          <NonVehicleYMME
            onOpenYMME={handleOpenYmme}
            nonShopPage={nonShopPage}
            categoryModelData={categoryModelData}
          />
        )}
        <YMMEModal
          onUpdateVehicleSuccess={() => {
            isBrowsePageType && void redirectAfterVehicleChanged();
            closeYmme();
          }}
          showYmme={showYmme}
          closeYmmeWidget={closeYmme}
          pageType={pageType}
          selectedMethod={
            isMobile && !vehicleAddLabel.includes(labels.lblAddYear) ? '' : selectedMethod
          }
        />
      </div>
    </ContentContainer>
  );
}
