/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import SpacerStyles from './CMSSpacer.module.scss';

export type CMSSpacerProps = {
  children: React.ReactNode;
  id?: string;
  shouldIgnoreCarouselSpacing?: boolean;
  removeImagePadding?: boolean | { s?: boolean; m?: boolean; l?: boolean };
  shouldReduceSpacing?: boolean;
  removeHorizontalSpacing?: boolean | { s?: boolean; m?: boolean; l?: boolean };
  removeVerticalSpacing?: boolean | { s?: boolean; m?: boolean; l?: boolean };
  className?: string;
  homePage?: boolean;
  landingPage?: boolean;
  shelfPage?: boolean;
  shouldUseTitleSpacing?: boolean;
  shouldUseTopCategorySpacing?: boolean;
};

export function CMSSpacer({
  children,
  id,
  shouldIgnoreCarouselSpacing,
  removeImagePadding,
  shouldReduceSpacing,
  shouldUseTitleSpacing,
  removeHorizontalSpacing,
  removeVerticalSpacing,
  shouldUseTopCategorySpacing,
  className,
  homePage,
  landingPage,
  shelfPage,
}: CMSSpacerProps) {
  return (
    <div
      id={id}
      data-testid={`cms-spacer-${id}`}
      className={cx(
        SpacerStyles.container,
        {
          [SpacerStyles.shouldUseTopCategorySpacing]: shouldUseTopCategorySpacing,
          [SpacerStyles.reducedSpacing]: shouldReduceSpacing,
          [SpacerStyles.titleSpacing]: shouldUseTitleSpacing,
          [SpacerStyles.ignoreCarouselSpacing]:
            typeof shouldIgnoreCarouselSpacing === 'boolean' && shouldIgnoreCarouselSpacing,
          [SpacerStyles.removeHorizontalSpacing]:
            typeof removeHorizontalSpacing === 'boolean' && removeHorizontalSpacing,
          [SpacerStyles.removeHorizontalSpacingS]:
            typeof removeHorizontalSpacing === 'object' && removeHorizontalSpacing.s,
          [SpacerStyles.removeHorizontalSpacingM]:
            typeof removeHorizontalSpacing === 'object' && removeHorizontalSpacing.m,
          [SpacerStyles.removeHorizontalSpacingL]:
            typeof removeHorizontalSpacing === 'object' && removeHorizontalSpacing.l,
          [SpacerStyles.removeVerticalSpacing]:
            typeof removeVerticalSpacing === 'boolean' && removeVerticalSpacing,
          [SpacerStyles.removeVerticalSpacingS]:
            typeof removeVerticalSpacing === 'object' && removeVerticalSpacing.s,
          [SpacerStyles.removeVerticalSpacingM]:
            typeof removeVerticalSpacing === 'object' && removeVerticalSpacing.m,
          [SpacerStyles.removeVerticalSpacingL]:
            typeof removeVerticalSpacing === 'object' && removeVerticalSpacing.l,
          [SpacerStyles.removeImagePadding]:
            typeof removeImagePadding === 'boolean' && removeImagePadding,
          [SpacerStyles.removeImagePaddingS]:
            typeof removeImagePadding === 'object' && removeImagePadding.s,
          [SpacerStyles.removeImagePaddingM]:
            typeof removeImagePadding === 'object' && removeImagePadding.m,
          [SpacerStyles.removeImagePaddingL]:
            typeof removeImagePadding === 'object' && removeImagePadding.l,
          [SpacerStyles.homePage]: homePage,
          [SpacerStyles.landingPage]: landingPage,
          [SpacerStyles.shelfPage]: shelfPage,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
