/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type VehicleSummary } from '@/types';
import styles from './AltYMMEContentBlock.module.scss';
import cx from 'classnames';
import NextImage from '@/components/NextImage';
import { useLabels } from '@/hooks/useLabels';

type Props = {
  preferredVehicle: VehicleSummary;
  setShowYmme: (open: boolean) => void;
  handleShopWithoutVehicle: () => void;
};

const labelMap = {
  lblCurrentlyShoppingFor: 'label_CurrentlyShoppingFor',
  lblSubModel: 'label_SubModel',
  lblEngine: 'label_Engine',
  lblChangeVehicle: 'button_YMME_Body_ChangeVehicle',
  lblShopWithoutVehicle: 'button_YMME_Body_ShopWithoutVehicle',
};

export const VehicleSelectedYMME = ({
  preferredVehicle,
  setShowYmme,
  handleShopWithoutVehicle,
}: Props) => {
  const vehicleIconOrange = {
    src: '/images/vehicle-new-orange.svg',
    alt: 'car-icon',
  };

  const vehicleImage =
    preferredVehicle && !!preferredVehicle.vehicleImageURL ? (
      <NextImage
        src={preferredVehicle.vehicleImageURL}
        alt=""
        width={100}
        height={54.29}
        className={styles.image}
      />
    ) : (
      <NextImage src={vehicleIconOrange.src} alt={vehicleIconOrange.alt} width={80} height={50} />
    );

  const labels = useLabels(labelMap);

  return (
    <div className={styles.hasVehicleContent}>
      <div className={styles.subtitle}>
        <p>{labels.lblCurrentlyShoppingFor}:</p>
        <button type="button" onClick={() => setShowYmme(true)} className={styles.underlinedButton}>
          {labels.lblChangeVehicle}
        </button>
      </div>
      <div className={styles.vehicleInfo}>
        <div className={styles.vehicleInfoDetails}>
          <span
            className={cx(styles.noVehicleImageStyles, {
              [styles.vehicleImageStyles]: !!preferredVehicle?.vehicleImageURL,
            })}
          >
            {vehicleImage}
          </span>
          <div>
            <h2>
              {preferredVehicle.year} {preferredVehicle.make} {preferredVehicle.model}
            </h2>
            <p>
              <span>{labels.lblEngine}:</span> {preferredVehicle.engine}
            </p>
          </div>
        </div>
        <div className={cx(styles.vehicleInfoActions, styles.buttons)}>
          <button type="button" onClick={() => setShowYmme(true)}>
            {labels.lblChangeVehicle}
          </button>
          <button
            type="button"
            onClick={handleShopWithoutVehicle}
            className={styles.underlinedButton}
          >
            {labels.lblShopWithoutVehicle}
          </button>
        </div>
      </div>
    </div>
  );
};
