/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './AltYMMEContentBlock.module.scss';
import cx from 'classnames';
import NextImage from '@/components/NextImage';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { type CategoryByModelData } from '../../../category/interface';

type Props = {
  onOpenYMME: (method: string) => void;
  nonShopPage?: boolean;
  categoryModelData?: CategoryByModelData;
};

const labelMap = {
  lblInlineEntryPointTitle: 'label_InlineEntryPointTitle',
  lblInlineEntryPointSubtitle: 'label_InlineEntryPointSubtitle',
  lblAddVehicle: 'button_YMME_Body_AddVehicle',
  lblAddMakeModel: 'add_make_model',
  lblAddLicensePlate: 'button_YMME_Body_AddLicensePlate',
  lblAddVIN: 'button_YMME_Body_AddVIN',
  lblMakeModel: 'label_MakeModel',
  lblLicensePlate: 'label_LicensePlate',
  lblVin: 'label_Vin',
  lblGetExactFit: 'label_YMME_GetAnExactFitForYour',
};

export const NonVehicleYMME = ({ onOpenYMME, categoryModelData, nonShopPage = false }: Props) => {
  const [modelName, setModelName] = useState('');
  const labels = useLabels(labelMap);
  const router = useRouter();
  const locale = useLocale();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMX = locale === countryCodes.mx;
  const isBR = locale === countryCodes.ptBr;

  const plusSignBlack = {
    src: '/images/plus-sign-black.svg',
    alt: 'plus-sign',
  };
  const ymmeCarIcon = {
    src: '/images/vehicle/ymme-car.svg',
    alt: 'car-icon',
  };

  const isPartsRoute =
    router.asPath.startsWith('/parts') || router.asPath.startsWith('/refacciones');
  const seoUrlPathLength = router.query.seoUrlPath?.length ?? 0;
  const isCategoryPage = isPartsRoute && seoUrlPathLength >= 2;

  useEffect(() => {
    if (categoryModelData) {
      if (categoryModelData.title && seoUrlPathLength === 2) {
        const titleParts = categoryModelData.title.split(' by ');
        if (titleParts.length > 1) {
          setModelName(titleParts[1].split(' by')[0]);
        }
      }

      if (categoryModelData.shopByMMYList && seoUrlPathLength === 3) {
        const foundModel = categoryModelData.shopByMMYList.find(
          (model) => model.seoUrl === router.asPath
        );
        setModelName(foundModel?.name || '');
      }
    }
  }, [categoryModelData, seoUrlPathLength, router.asPath]);

  const vehicleAddLabel = isMobile ? labels.lblAddVehicle : labels.lblAddMakeModel;

  const shouldShowDesktopIcon = (!isMobile && nonShopPage) || !nonShopPage;
  const shouldShowMobileIcon = isMobile && nonShopPage;

  return (
    <div className={styles.content}>
      <div
        className={cx(styles.titleContainer, {
          [styles.brTitleContainer]: isBR,
        })}
      >
        <div className={styles.titleText} data-testid="ymme-content-title">
          {shouldShowDesktopIcon && (
            <NextImage
              src={ymmeCarIcon.src}
              alt={ymmeCarIcon.alt}
              width={nonShopPage && !isMobile ? 40 : 31.99}
              height={nonShopPage && !isMobile ? 40 : 31.99}
              className={cx(styles.desktopCarIcon)}
            />
          )}
          <h2>
            {shouldShowMobileIcon && (
              <NextImage
                src={ymmeCarIcon.src}
                alt={ymmeCarIcon.alt}
                width={31.99}
                height={31.99}
                className={cx(styles.mobileCarIcon)}
              />
            )}
            {labels.lblInlineEntryPointTitle}
          </h2>
        </div>
        {isCategoryPage && modelName ? (
          <p>
            {labels.lblGetExactFit} <strong>{modelName}</strong>
          </p>
        ) : (
          <p>{labels.lblInlineEntryPointSubtitle}</p>
        )}
      </div>
      <div className={styles.buttons}>
        <button type="button" onClick={() => onOpenYMME(labels.lblMakeModel)}>
          <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={12} height={12} />{' '}
          {vehicleAddLabel}
        </button>
        {!isMX && !isBR && (
          <button type="button" onClick={() => onOpenYMME(labels.lblLicensePlate)}>
            <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={12} height={12} />{' '}
            {labels.lblAddLicensePlate}
          </button>
        )}
        <button type="button" onClick={() => onOpenYMME(labels.lblVin)}>
          <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={12} height={12} />{' '}
          {labels.lblAddVIN}
        </button>
      </div>
    </div>
  );
};
