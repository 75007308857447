/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import cx from 'classnames';
import Hidden from '@/components/Hidden';
import { Button } from '@/components/Button';
import { SmartLink as Link } from '../../../utils/smartLink';
import azCommonStyles from '../../../theme/globals.module.scss';
import Modal from '@/components/Modal';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';

type Props = {
  content: any;
  overrideClassName?: string;
  handleToggleLegalModal?: (isModalOpened: boolean) => void;
  isLightBackGround?: boolean;
  isQuotient?: boolean;
  isWithLegalText?: boolean;
  isCatBanner?: boolean;
  isSecondBanner?: boolean;
  isHeroCarouselBanner?: boolean;
  isCMSHeroBanner?: boolean;
  isCMSSuperDuperHero?: boolean;
  singleLine?: boolean;
  linkColor?: string;
};

const labelMap = {
  label_ShelfPage_sponsored: 'label_ShelfPage_sponsored',
};

export const Legal = ({
  content,
  overrideClassName = '',
  handleToggleLegalModal,
  isLightBackGround,
  isQuotient,
  isWithLegalText,
  isCatBanner,
  isSecondBanner,
  isHeroCarouselBanner = false,
  isCMSHeroBanner = false,
  isCMSSuperDuperHero = false,
  singleLine = false,
  linkColor,
}: Props) => {
  const labels = useLabels(labelMap);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [PDFLink, setPDFLink] = useState('');

  const openPDFModal = (legalLink: string) => {
    setIsPDFModalOpen(true);
    setPDFLink(legalLink);
  };

  const closePDFModal = () => {
    setIsPDFModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    handleToggleLegalModal?.(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    handleToggleLegalModal?.(false);
  };

  const { legalText, legalLinkName, legalCopy, legalLink, imageLink } = content || {};

  const hasPdf = legalLink?.endsWith('.pdf');

  const isLegalTextAvailable = legalText && legalLinkName;

  const firstPartyDataNoLegal =
    !legalText && !legalLinkName && !legalCopy && !legalLink && !isQuotient;

  //fix Brazil phase one issue
  const sponsoredLabel = labels.label_ShelfPage_sponsored?.toLowerCase();

  const pdfBody = <embed src={PDFLink} type="application/pdf" width="100%" height="800px" />;

  return (
    <>
      {!firstPartyDataNoLegal && !!content && (
        <div
          className={cx({
            [overrideClassName]: overrideClassName,
            [styles.legalContainer]: !isQuotient && !singleLine,
            [styles.withQuotient]: isQuotient && !isHeroCarouselBanner,
            [styles.quotientCatBanner]: isCatBanner && !isSecondBanner,
            [styles.legalTextAvailable]: isLegalTextAvailable,
            [styles.secondBannerStyles]: isSecondBanner,
            [styles.heroCarouselBanner]: isHeroCarouselBanner && !isCMSHeroBanner,
            [styles.heroCarouselBannerCMS]: isCMSHeroBanner,
            [styles.superDuperHero]: isCMSSuperDuperHero,
          })}
        >
          <div className={cx(singleLine && styles.singleLineTextContainer)}>
            {legalText && (
              <p
                className={cx(
                  styles.legalText,
                  styles.displayBlock,
                  azCommonStyles['az-caption'],
                  isLightBackGround && styles.darkerText,
                  singleLine && styles.singleLineLegalText
                )}
                style={linkColor ? { color: linkColor } : {}}
                tabIndex={0}
              >
                {legalText}
              </p>
            )}
            {legalCopy && legalLinkName ? (
              <Button
                variant="link"
                onClick={openModal}
                customClass={cx(
                  styles.detailsButton,
                  azCommonStyles['az-caption'],
                  isLightBackGround && styles.darkerText
                )}
                dataTestId={`${legalLinkName.split(' ').join('-')}-link`}
                style={
                  linkColor ? { color: linkColor, textDecoration: `${linkColor} underline` } : {}
                }
              >
                {legalLinkName}
              </Button>
            ) : legalLinkName ? (
              <Link
                to={legalLink ? (hasPdf ? '' : legalLink) : imageLink ? imageLink : '#'}
                className={cx(
                  styles.legalLink,
                  azCommonStyles['az-caption'],
                  isLightBackGround && styles.darkerText
                )}
                style={linkColor ? { color: linkColor } : {}}
                onClick={hasPdf ? () => openPDFModal(legalLink) : undefined}
              >
                {legalLinkName}
              </Link>
            ) : null}
          </div>
          {!isHeroCarouselBanner && isQuotient && (
            <div className={cx(styles.displayFlex, styles.leftMarginLargeScreens)}>
              <div
                className={cx(
                  styles.quotientText,
                  styles.displayFlex,
                  azCommonStyles['az-caption'],
                  isLightBackGround && styles.darkerText,
                  {
                    [styles.quotientCatBanner]: isCatBanner && !isSecondBanner,
                  }
                )}
              >
                {(isWithLegalText || legalLinkName) && (
                  <Hidden lgUp>
                    <span className={styles.quotientPipe}>|</span>
                  </Hidden>
                )}
                <span className={styles.sponsoredLabel}>{sponsoredLabel}</span>
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={isPDFModalOpen}
        body={pdfBody}
        disableEscapeKeyDown={false}
        desktopSize="large"
        tabletSize="small"
        handleBack={closePDFModal}
        handleClose={closePDFModal}
        showBackButton
        showCloseButton
        fullWidth={true}
      />
      <Modal
        isOpen={isModalOpen}
        disableEscapeKeyDown={false}
        desktopSize="large"
        tabletSize="small"
        handleBack={closeModal}
        handleClose={closeModal}
        showBackButton
        showCloseButton
        body={
          <div
            className={styles.dynamicContent}
            dangerouslySetInnerHTML={(() => ({
              __html: legalCopy,
            }))()}
          />
        }
      />
    </>
  );
};
